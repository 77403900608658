"use client";
import { logger } from "@/lib/logging/logger";

import { Card } from "@/components/ui";
import { toast } from "@/components/ui/use-toast";
import { TASK_FAILED_TIME_SECONDS } from "@/lib/constants";
import { getOpeningName, getVariationRoot } from "@/lib/openings/openings";
import {
  formatGameSource,
  formatShortenPgn,
  formatString,
  isAdminUser,
  staleGame,
} from "@/lib/utils";
import { ClientUser } from "@/types/ClientUser";
import { ChessGamePreview } from "@/types/Move";
import moment from "moment";
import "moment/locale/ro";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useEffect, useMemo, useState } from "react";
import { AccuracyBar } from "./AccuracyBar";
import { GameCardActions } from "./GameCardActions";
import { GamestatusPopover } from "./GamestatusPopover";
import { PgnCardActions } from "./PgnCardActions";
import { PrivacyActions } from "./PrivacyActions";
import { SafeImage } from "./SafeImage";
import { getPublicAssetPath } from "@/lib/asssetUtils";

import {
  addGameToCollection,
  createFavoritesCollectionWithGame,
  removeGameFromCollection,
} from "@/app/services/collection.service";

import resubmitGame from "@/lib/game/resubmitGame";
import { useLoadingModal } from "@/hooks/useLoadingModal";
import { BookmarkPlusIcon, Share2Icon } from "lucide-react";
import { throttle } from "@/lib/throttlev2";
import { createPortal } from "react-dom";
import { CollectionWithGamesPreview } from "@/types/FullCollectionType";
import { useTranslation } from "react-i18next";
import CollectionSelectorModal from "@/app/[locale]/(dashboard)/(game)/collections/components/Game_Collection_Management/CollectionSelection";
import {
  getDbCodeToBcp47,
  getLanguageCodeFlag,
} from "@/lib/multi-language/multiLanguage";
import { TLanguageCode } from "countries-list";

interface GameDescriptionProps {
  game: ChessGamePreview;
  user: ClientUser | null;
  fragment?: string;
  apiFragment?: string;
  handleSelectOpening: (opening: string) => void;
  CardActions?: typeof GameCardActions | typeof PgnCardActions;
  sharedBy?: string;
  collections?: CollectionWithGamesPreview[] | null;
  setCollections: React.Dispatch<
    React.SetStateAction<CollectionWithGamesPreview[] | undefined>
  >;
  isOnLanding?: boolean;
}

const editCollection = ({
  collectionId,
  collection,
  setCollections,
}: {
  collectionId: string;
  collection: CollectionWithGamesPreview;
  setCollections: React.Dispatch<
    React.SetStateAction<CollectionWithGamesPreview[] | undefined>
  >;
}) => {
  setCollections((prev) =>
    (prev || []).map((initialCollection) => {
      if (initialCollection.id === collectionId) return collection;
      return initialCollection;
    }),
  );
};

const addToCollections = ({
  newCollection,
  setCollections,
}: {
  newCollection: CollectionWithGamesPreview;
  setCollections: React.Dispatch<
    React.SetStateAction<CollectionWithGamesPreview[] | undefined>
  >;
}) => {
  setCollections((prev) => {
    if (!prev) return [newCollection];
    return [...prev, newCollection];
  });
};

export const GameDescription = React.forwardRef<
  React.ElementRef<typeof Card>,
  GameDescriptionProps
>(
  (
    {
      game,
      user,
      handleSelectOpening,
      fragment = "/game",
      apiFragment = "/api/game",
      CardActions,
      sharedBy,
      collections,
      setCollections,
      isOnLanding,
    }: GameDescriptionProps,
    ref,
  ) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const router = useRouter();
    const { t, i18n } = useTranslation("common");
    let locale = i18n.language;
    moment.locale(locale);
    const fallbackUrl = getPublicAssetPath("/assets/game_placeholder.png");
    const isFavorite = useMemo(
      () =>
        (collections || []).some((x) =>
          x.gamesPreview.some((y) => y.id === game.id),
        ),
      [collections],
    );
    const flagEmoji = useMemo(
      () =>
        getLanguageCodeFlag(
          getDbCodeToBcp47(game?.gameScore?.language || "") as TLanguageCode,
        ),
      [game?.gameScore?.language],
    );
    const loadingModal = useLoadingModal();

    useEffect(() => {
      const handleResize = throttle(() => {
        setIsMobile(window.innerWidth < 768);
      }, 25);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleClickOpening = (e: any) => {
      e.preventDefault();
      handleSelectOpening(
        game.opening ? game.opening : getOpeningName(game.pgn),
      );
    };

    const handleFavorite = async () => {
      try {
        if (!collections) return;
        let favoriteCol = collections.find((x) => x.name === "Favorites");
        if (!favoriteCol) {
          const data = await createFavoritesCollectionWithGame(game.id);
          addToCollections({ newCollection: data, setCollections });
          favoriteCol = data;
        }
        if (isFavorite) {
          // remove
          await removeGameFromCollection(favoriteCol.id, game.id);
          editCollection({
            setCollections,
            collectionId: favoriteCol.id,
            collection: {
              ...favoriteCol,
              gamesPreview: favoriteCol.gamesPreview.filter(
                (g) => g.id !== game.id,
              ),
            },
          });
          toast({
            description: t("gameCard.removedFav"),
            duration: 3000,
          });
        } else {
          // add
          const updatedCollection = await addGameToCollection(
            favoriteCol.id,
            game.id,
          );
          editCollection({
            setCollections,
            collectionId: favoriteCol.id,
            collection: {
              ...favoriteCol,
              gamesPreview: [
                ...favoriteCol.gamesPreview,
                {
                  createdAt: game.createdAt,
                  id: game.id,
                  pgn: game.pgn ?? "",
                  gameSource: game.gameSource,
                  gameStatus: game.status,
                },
              ],
            },
          });
          toast({
            description: t("gameCard.addedFav"),
            duration: 3000,
          });
        }
      } catch (error) {
        console.error(error);
        toast({
          variant: "destructive",
          description: t("gameCard.alertDialog.error"),
          duration: 3000,
        });
      }
    };

    const resubmit = async () => {
      if (!game) {
        return;
      }
      if (!game.images.original) {
        toast({
          description: "No image",
          duration: 3000,
        });
      } else {
        try {
          loadingModal.onOpen();
          const data = await resubmitGame(game);
          loadingModal.onClose();
          router.push(`/game/${data.id}`);

          toast({
            description: t("gameCard.status.success"),
            duration: 3000,
          });
        } catch (error) {
          loadingModal.onClose();
          logger.info(error);
          toast({
            variant: "destructive",
            description: t("gameCard.alertDialog.error"),
            duration: 3000,
          });
        }
      }
    };

    const handleResubmit = () => {
      resubmit();
    };

    const canEdit = () => {
      return !!user;
    };

    const isFailedGame =
      ((game.status !== "SUCCESS" && game.status !== "COMPLETED") ||
        ((game.status === "SUCCESS" || game.status === "COMPLETED") &&
          !game.pgn)) &&
      staleGame(game.createdAt, TASK_FAILED_TIME_SECONDS);

    const getUsername = (game: ChessGamePreview) => {
      if (!game.user) return "anonymous";
      if (game.user.name) return game.user.name;
      if (game.user.email) return game.user.email;
      return "anonymous";
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
      <>
        {isDialogOpen &&
          document?.body &&
          createPortal(
            <CollectionSelectorModal
              isOpen={isDialogOpen}
              setIsOpen={setIsDialogOpen}
              gameId={game.id}
            />,
            document.body,
          )}
        <Link href={`${fragment}/${game.id}`} className="z-10">
          <div className="flex text-white p-4 space-x-4 rounded-xl shadow-custom bg-white">
            {!isMobile && (
              <div className="flex relative shrink-0 w-[90px] h-[90px] sm:w-[170px] sm:h-[190px] bg-white rounded-md overflow-hidden self-center">
                <SafeImage
                  // src={game.imgUrl}
                  src={fallbackUrl}
                  fallbackUrl={fallbackUrl}
                  fill
                  sizes="(max-width: 640px) 50vw, 20vw"
                  quality={75}
                  alt="PGN photo"
                  className="object-cover"
                />
                {!isOnLanding && (
                  <GamestatusPopover
                    absolute
                    status={isFailedGame ? "FAILED" : game.status}
                    handleResubmit={handleResubmit}
                    refunded={game.refunded}
                  />
                )}
                {flagEmoji !== "🌐" && (
                  <div className="absolute top-0 left-2 text-2xl">
                    {flagEmoji}
                  </div>
                )}
              </div>
            )}
            <div className="flex overflow-hidden flex-col w-full items-start justify-between">
              <div className="flex gap-4 justify-between w-full items-start mb-4 sm:mb-0">
                <div className="min-w-0 flex flex-row gap-2">
                  {isMobile && flagEmoji !== "🌐" && (
                    <div className="text-xl">{flagEmoji}</div>
                  )}
                  <div className="flex truncate grow flex-col min-w-0">
                    <p
                      className="whitespace-nowrap truncate text-left font-bold text-slate-800 hover:underline"
                      onClick={handleClickOpening}
                    >
                      {isFailedGame
                        ? t("gameCard.failed")
                        : game.opening
                          ? getVariationRoot(game.opening)
                          : t("gameCard.noOpening")}
                    </p>
                    {!isOnLanding && (
                      <p
                        className="truncate text-xs sm:text-sm text-slate-600 text-left font-light"
                        title={moment(game.createdAt).format(
                          "DD MMM, YYYY HH:mm",
                        )}
                      >
                        {moment(game.createdAt).fromNow()}
                        {isAdminUser(user) && (
                          <Link href={`/games/${game.user?.id}`}>
                            {" "}
                            {t("by")}
                            {` ${getUsername(game)}`}{" "}
                          </Link>
                        )}
                      </p>
                    )}
                    {sharedBy ? (
                      <div className="truncate text-xs flex items-end h-max gap-2 text-slate-400 text-left">
                        <Share2Icon className="h-4 w-4 text-blue-500" />{" "}
                        <p>{t("gameCard.sharedBy", { sharedBy })}</p>
                      </div>
                    ) : (
                      <div className="h-4 w-4"></div>
                      // Spacer ^, neccessary since addition of sharedBy to mantain vertical centering of swords
                    )}
                  </div>
                </div>
                {!!canEdit() && (
                  <div className="ml-auto flex flex-none items-center gap-2 text-slate-950">
                    {!isMobile && (
                      <>
                        <button
                          className="bg-slate-100 whitespace-nowrap shrink-0 flex items-center p-2 gap-3 rounded-md"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setIsDialogOpen(true);
                          }}
                        >
                          <p className="font-semibold text-slate-800">
                            {t("gameCard.save")}
                          </p>
                          <BookmarkPlusIcon className="w-[24px] h-[24px] text-brand-secondary" />
                        </button>

                        <PrivacyActions
                          privacyObject={game}
                          user={user}
                          apiFragment={"/api/game/privacy"}
                          frontendFragment="/game"
                        />
                      </>
                    )}
                    {CardActions && (
                      <CardActions
                        game={game}
                        user={user}
                        fragment={apiFragment}
                        isFavorite={isFavorite}
                        handleFavorite={handleFavorite}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center w-full py-2">
                <div className="flex items-center space-x-4 self-center justify-between w-full">
                  <div className="flex flex-col items-center w-full shrink-1">
                    <span className="font-bold text-slate-800 text-lg sm:text-xl ml-auto">
                      {game.attachedPgn && game.attachedPgn.white
                        ? game.attachedPgn.white
                        : t("gameCard.white")}
                    </span>
                  </div>
                  <Image
                    alt="icon"
                    src="/assets/swords_icon.svg"
                    width={55}
                    height={55}
                  />
                  <div className="flex flex-col items-center w-full shrink-1">
                    <span className="font-bold text-slate-800 text-ls sm:text-xl mr-auto">
                      {game.attachedPgn && game.attachedPgn.black
                        ? game.attachedPgn.black
                        : t("gameCard.black")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="h-4 w-4"></div> {/* Spacer */}
              <div className="flex items-start mt-4 sm:mt-0 justify-between w-full items-end max-w-full">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-xs sm:text-sm font-semibold text-slate-800 text-left max-w-full whitespace-nowrap text-ellipsis overflow-hidden pr-4">
                    {game.pgn && game.opening
                      ? formatString(game.opening, 30)
                      : t("gameCard.noOpening")}
                  </p>
                  <p className="text-xs sm:text-sm text-slate-600 text-left font-light">
                    {game.pgn &&
                      formatShortenPgn(game.pgn, isMobile ? 2 : 3, t)}
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  {game.gameSource !== "IMAGE" && (
                    <p className="text-gray-400 text-xs sm:text-sm font-bold text-left w-full">
                      {t("gameCard.source")}{" "}
                      {formatGameSource(game.gameSource, t)}
                    </p>
                  )}
                  {!!game.gameScore &&
                    !!game.gameScore.ocrAccuracy &&
                    !!game.gameScore.fischerScoreAvg &&
                    game.gameSource === "IMAGE" && (
                      <div className="relative">
                        <p className="font-semibold text-xs sm:text-sm text-slate-800 whitespace-nowrap">
                          {t("gameCard.estAcc")}
                        </p>
                        <AccuracyBar
                          ocrAccuracy={game.gameScore?.ocrAccuracy}
                        />
                      </div>
                    )}
                </div>
              </div>
              {!isOnLanding && isMobile && (
                <div className="flex flex-col w-full items-center mt-2 gap-1">
                  {!!canEdit() && (
                    <PrivacyActions
                      privacyObject={game}
                      user={user}
                      apiFragment={"/api/game/privacy"}
                      frontendFragment="/game"
                    />
                  )}
                  <div className="flex space-x-4 items-center">
                    <GamestatusPopover
                      status={isFailedGame ? "FAILED" : game.status}
                      handleResubmit={handleResubmit}
                      refunded={game.refunded}
                    />
                    {!!canEdit() && (
                      <button
                        className="bg-slate-100 shrink-0 flex items-center p-2 gap-3 rounded-md"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setIsDialogOpen(true);
                        }}
                      >
                        <p className="font-semibold text-slate-800">
                          {t("gameCard.save")}
                        </p>
                        <BookmarkPlusIcon className="w-[24px] h-[24px] text-brand-secondary" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Link>
      </>
    );
  },
);
