import { Checkbox } from "@/components/ui";
import { Privacy } from "@/prisma/schema/mysql";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import { TFunction } from "i18next";
interface ISharingButton {
  privacy: Privacy;
  handleCopyLink: (e: any) => void;
  handleChange: (e: any) => void;
  t: TFunction;
}
export default function SharingButton({
  privacy,
  handleCopyLink,
  handleChange,
  t,
}: ISharingButton) {
  return (
    <div className="flex-none whitespace-nowrap min-w-max flex gap-3 text-black font-semibold">
      {privacy === "UNLISTED" && (
        <div
          className="bg-slate-100 flex items-center p-2 gap-3 rounded-md"
          onClick={handleCopyLink}
        >
          <p className="font-semibold text-slate-800">
            {t("gameCard.privacyActions.copy")}
          </p>
          <DocumentDuplicateIcon className="w-[24px] h-[24px] fill-brand-secondary" />
        </div>
      )}
      <div
        className="bg-slate-100 flex items-center p-2 gap-3 rounded-md"
        onClick={handleChange}
      >
        {t("gameCard.privacyActions.sharing")}
        <Checkbox
          className="w-[24px] h-[24px] data-[state=checked]:bg-brand-secondary data-[state=checked]:text-white border-brand-secondary"
          checked={privacy === "UNLISTED"}
        />
      </div>
    </div>
  );
}
