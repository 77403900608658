"use client";

import { memo, useEffect, useState } from "react";

import { toast } from "@/components/ui/use-toast";
import { ClientUser } from "@/types/ClientUser";
import { Privacy } from "@/prisma/schema/mysql";
import axios from "axios";
import { cn, copyToClipboard, isAdminUser } from "@/lib/utils";

import { useTranslation } from "react-i18next";
import SharingButton from "./SharingButton";

type PrivacyInfoRequirements = {
  id: string;
  privacy?: Privacy;
  user?: { id: string | null } | null;
};

type PrivacyActionProps<T extends PrivacyInfoRequirements> = {
  privacyObject: T;
  apiFragment: string;
  frontendFragment: string;
  user: ClientUser | null;
  className?: string;
};

function PrivacyAction<T extends PrivacyInfoRequirements>({
  privacyObject,
  user,
  apiFragment,
  frontendFragment,
  className,
}: Readonly<PrivacyActionProps<T>>) {
  const [privacy, setPrivacy] = useState<Privacy>(
    privacyObject.privacy ?? "PRIVATE",
  );
  const { t } = useTranslation("common");
  useEffect(() => {
    setPrivacy(privacyObject.privacy as Privacy);
  }, [privacyObject.privacy]);

  const canEdit = () => {
    if (!user) {
      return false;
    }

    if (isAdminUser(user) || privacyObject.user?.id === user.id) {
      return true;
    }

    return false;
  };

  const handleChange = async (e: any) => {
    e.preventDefault();
    if (!canEdit()) return;
    const oldPrivacy = privacy;
    try {
      const newPrivacy: Privacy =
        privacy === "PRIVATE" ? "UNLISTED" : "PRIVATE";
      setPrivacy(newPrivacy);
      await axios.patch(`${apiFragment}/${privacyObject.id}`, {
        privacy: newPrivacy,
      });
    } catch (error) {
      const message = t("gameCard.privacyActions.errorMessage");
      toast({
        variant: "destructive",
        description: message,
        duration: 2000,
      });
      setPrivacy(oldPrivacy);
    }
  };

  const handleCopyLink = (e: any) => {
    e.preventDefault();
    const { protocol, host, pathname } = window.location;
    console.log(protocol, host, pathname);
    const url = new URL(
      `${protocol}//${host}/${frontendFragment}/${privacyObject.id}`,
    ).toString();
    copyToClipboard(url);
    toast({
      description: t("gameCard.privacyActions.copyMessage"),
    });
  };

  return (
    <SharingButton
      privacy={privacy}
      handleCopyLink={handleCopyLink}
      handleChange={handleChange}
      t={t}
    />
  );
}

export const PrivacyActions = memo(PrivacyAction);
